<template>
  <div>
    <div class="menu">
      <div id="nav">
        <b-container class="menuContainer">
          <b-navbar toggleable="lg" type="dark" variant="info">
            <b-button class="burgerOuter" @click="toggle"
              ><b-img
                class="burger"
                :src="
                  config.VUE_APP_WP_DIGITAL_OCEAN + '2020/06/burger_menu.png'
                "
              ></b-img
            ></b-button>
            <template v-if="isMobile(true) && live_scoring == 'Y'">
              <b-nav-item
                v-if="
                  this.CurrentTitle == 'Live Scoring Monitor' ||
                    this.CurrentTitle.includes('Scores Only') ||
                    this.CurrentTitle.includes('Scoreboard')
                "
                class="menuBar LeaderboardBtnMob pulse"
                :to="{
                  name: 'reports-page',
                  query: {
                    url: this.CurrentReport,
                    id: this.seasons,
                    code: this.code,
                    title: this.CurrentTitle
                  }
                }"
                >Leaderboard</b-nav-item
              >
            </template>

            <b-navbar-brand to="/">
              <img
                class="logoImg"
                :src="
                  config.VUE_APP_WP_DIGITAL_OCEAN +
                    'sites/3/2020/06/LET20-LOGO-LET-Access-Series-White-1024x456-1.png'
                "
              />
            </b-navbar-brand>
            <template v-if="live_scoring == 'Y'">
              <b-nav-item
                v-if="
                  this.CurrentTitle == 'Live Scoring Monitor' ||
                    this.CurrentTitle.includes('Scores Only') ||
                    this.CurrentTitle.includes('Scoreboard')
                "
                class="menuBar mobHide LeaderboardBtn pulse break"
                :to="{
                  name: 'reports-page',
                  query: {
                    url: this.CurrentReport,
                    id: this.seasons,
                    code: this.code,
                    title: this.CurrentTitle
                  }
                }"
              >
                Leaderboard
              </b-nav-item>
            </template>
            <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

            <b-collapse id="nav-collapse" is-nav class="navbar-brand mx-auto">
              <b-navbar-nav class="middleSec">
                <b-nav-item
                  class="menuBar mobHide break"
                  href="https://solheimcup2023.eu/"
                  target="_blank"
                >
                  Solheim Cup
                </b-nav-item>
                <b-nav-item
                  class="menuBar mobHide break"
                  href="https://www.lpga.com/"
                  target="_blank"
                  >LPGA</b-nav-item
                >
                <b-nav-item
                  :class="{
                    leadRight:
                      this.CurrentTitle == 'Live Scoring Monitor' ||
                      this.CurrentTitle.includes('Scores Only')
                  }"
                  class="break menuBar mobHide rightLink LETAS"
                  href="https://ladieseuropeantour.com/"
                  target="_blank"
                  >LET</b-nav-item
                >
              </b-navbar-nav>

              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <template class="socialIcons-Group">
                  <b-nav-item
                    class="break menuBar mobHide socialIcon"
                    href="https://www.tiktok.com/@letaccess?lang=en"
                    target="_blank"
                  >
                    <font-awesome-icon class="icon" :icon="['fab', 'tiktok']" />
                  </b-nav-item>
                  <b-nav-item
                    class="break menuBar mobHide socialIcon"
                    :href="
                      'https://www.facebook.com/' + config.VUE_APP_FACEBOOK_NAME
                    "
                    target="_blank"
                  >
                    <font-awesome-icon
                      class="icon"
                      :icon="['fab', 'facebook']"
                    />
                  </b-nav-item>
                  <b-nav-item
                    class="break menuBar mobHide socialIcon"
                    :href="'https://twitter.com/' + config.VUE_APP_TWITTER_NAME"
                    target="_blank"
                  >
                    <img
                      class="newTwitter"
                      src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/logo-white.png"
                    />
                  </b-nav-item>
                  <b-nav-item
                    class="break menuBar mobHide socialIcon"
                    :href="
                      'https://www.instagram.com/' + config.VUE_APP_INSTA_NAME
                    "
                    target="_blank"
                  >
                    <font-awesome-icon
                      class="icon"
                      :icon="['fab', 'instagram']"
                    />
                  </b-nav-item>
                  <b-nav-item
                    class="break menuBar mobHide socialIcon"
                    :href="
                      'https://www.youtube.com/user/' +
                        config.VUE_APP_YOUTUBE_NAME
                    "
                    target="_blank"
                  >
                    <font-awesome-icon
                      class="icon"
                      :icon="['fab', 'youtube']"
                    />
                  </b-nav-item>
                  <b-nav-item
                    class="break menuBar mobHide socialIcon"
                    :href="
                      'https://www.flickr.com/photos/' +
                        config.VUE_APP_FLICKR_NAME
                    "
                    target="_blank"
                  >
                    <font-awesome-icon class="icon" :icon="['fab', 'flickr']" />
                  </b-nav-item>
                </template>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>

          <!-- <b-navbar type="light" toggleable="lg" id="topNav" align="center">
            <b-button class="burgerOuter" @click="toggle"
              ><b-img
                class="burger"
                :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2020/06/burger_menu.png'"
              ></b-img
            ></b-button>

            <template v-if="isMobile(true)">
              <b-nav-item v-if="this.CurrentTitle == 'Live Scoring Monitor' || this.CurrentTitle.includes('Scores Only') || this.CurrentTitle.includes('Scoreboard')" class="menuBar LeaderboardBtnMob pulse" :to="{
                  name: 'reports-page',
                  query: {
                    url: this.CurrentReport,
                    id: this.seasons,
                    code: this.code,
                    title: this.CurrentTitle,
                  },
                }">Leaderboard</b-nav-item>           
            </template>



            <b-navbar-brand to="/">
              <img
                class="logoImg"
                :src="config.VUE_APP_WP_DIGITAL_OCEAN + 'sites/3/2020/06/LET20-LOGO-LET-Access-Series-White-1024x456-1.png'"
              />
            </b-navbar-brand>
            <b-nav-item v-if="this.CurrentTitle == 'Live Scoring Monitor' || this.CurrentTitle.includes('Scores Only') || this.CurrentTitle.includes('Scoreboard')" class="menuBar mobHide LeaderboardBtn pulse break" :to="{
                name: 'reports-page',
                query: {
                  url: this.CurrentReport,
                  id: this.seasons,
                  code: this.code,
                  title: this.CurrentTitle,
                },
              }">Leaderboard</b-nav-item>
            <b-nav-item class="menuBar mobHide break" href="https://solheimcup2023.eu/" target="_blank">Solheim Cup</b-nav-item>
            <b-nav-item
              class="menuBar mobHide break"
              href="https://www.lpga.com/"
              target="_blank"
              >LPGA</b-nav-item
            >
            <b-nav-item
              :class="{leadRight : this.CurrentTitle == 'Live Scoring Monitor' || this.CurrentTitle.includes('Scores Only')}"
              class="break menuBar mobHide rightLink LETAS"
              href="https://letaccess.com/"
              target="_blank"
              >LET</b-nav-item
            >
            <template class="socialIcons-Group">
              <b-nav-item class="break menuBar mobHide socialIcon" href="https://www.tiktok.com/@letaccess?lang=en" target="_blank">
                <font-awesome-icon class="icon" :icon="['fab', 'tiktok']" />
              </b-nav-item>
              <b-nav-item class="break menuBar mobHide socialIcon" :href="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME" target="_blank">
                <font-awesome-icon class="icon" :icon="['fab', 'facebook']" />
              </b-nav-item>
              <b-nav-item class="break menuBar mobHide socialIcon" :href="'https://twitter.com/'+config.VUE_APP_TWITTER_NAME" target="_blank">
                <img class='newTwitter' src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/logo-white.png">
              </b-nav-item>
              <b-nav-item class="break menuBar mobHide socialIcon" :href="'https://www.instagram.com/'+config.VUE_APP_INSTA_NAME" target="_blank">
                <font-awesome-icon class="icon" :icon="['fab', 'instagram']" />
              </b-nav-item>
              <b-nav-item class="break menuBar mobHide socialIcon" :href="'https://www.youtube.com/user/'+config.VUE_APP_YOUTUBE_NAME" target="_blank">
                <font-awesome-icon class="icon" :icon="['fab', 'youtube']" />
              </b-nav-item>
              <b-nav-item class="break menuBar mobHide socialIcon" :href="'https://www.flickr.com/photos/'+config.VUE_APP_FLICKR_NAME" target="_blank">
                <font-awesome-icon class="icon" :icon="['fab', 'flickr']" />
              </b-nav-item>
            </template>
            
          </b-navbar> -->
        </b-container>
      </div>
    </div>
    <transition name="slide">
      <div class="slidein" :class="open ? 'open' : ''">
        <b-row>
          <b-col>
            <b-nav-item @click="toggle" to="/" class="mainItem"
              >Home</b-nav-item
            >
            <b-nav-item
              @click="toggle"
              class="mainItem"
              :to="'/tournaments-information'"
            >
              Tournaments
            </b-nav-item>
            <b-nav-item @click="toggle" class="mainItem" to="/news"
              >News</b-nav-item
            >
            <b-nav-item
              @click="toggle"
              class="mainItem"
              :to="{
                name: 'players'
              }"
              >Players</b-nav-item
            >
            <b-nav-item
              @click="toggle"
              class="mainItem"
              :to="{
                name: 'videos',
                query: { id: '' }
              }"
              >Videos</b-nav-item
            >
            <b-nav-item
              @click="toggle"
              class="mainItem"
              to="/the-letas-golf-podcast"
              >The LETAS Golf Podcast</b-nav-item
            >
            <b-nav-item @click="toggle"
              class="mainItem statsUpdate"
              :to="{
                name: 'stats',
                query: { id: seasons, full: true },
              }"
              >Stats</b-nav-item
            >
            <b-nav-item v-b-toggle="'collapse-2'" class="rankingsMenu mainItem">
              Rankings
              <font-awesome-icon
                class="arrowDown"
                :icon="['fas', 'arrow-down']"
              />
            </b-nav-item>
            <b-collapse id="collapse-2">
              <ul>
                <li class="liRanksLinks">
                  <b-nav-item
                    class="rankLinks"
                    @click="toggle"
                    :to="{
                      name: 'letas-order-of-merits',
                      query: { id: seasons, oom: 'LO' }
                    }"
                    >Order of Merit</b-nav-item
                  >
                </li>
                <li class="liRanksLinks">
                  <b-nav-item
                    class="rankLinks"
                    @click="toggle"
                    :to="{
                      name: 'letas-roty',
                      query: { id: seasons, oom: 'LR' }
                    }"
                    >Rookie of the Year</b-nav-item
                  >
                </li>
                <li class="liRanksLinks">
                  <b-nav-item
                    class="rankLinks"
                    @click="toggle"
                    :to="{
                      name: 'rolex-world-rankings-2',
                      query: { id: seasons, oom: 'WR' }
                    }"
                    >Rolex Ranking</b-nav-item
                  >
                </li>
              </ul>
            </b-collapse>
            <b-nav-item
              @click="toggle"
              class="mainItem"
              :to="'/celebrating-the-green/'"
              >Celebrating The Green</b-nav-item
            >
            <b-nav-item
              @click="toggle"
              class="mainItem"
              to="/partners-suppliers"
              >Partners & Suppliers</b-nav-item
            >
            <b-nav-item @click="toggle" class="mainItem" to='/about-letas-tour'>About LETAS</b-nav-item> 
            <b-nav-item @click="toggle" class="mainItem" to='/membership'>Membership</b-nav-item> 
            <template v-if="isMobile(true)">
              <b-nav-item class="mainItem" href='https://ladieseuropeantour.com/'>Ladies European Tour</b-nav-item> 
            </template>
            <!-- <b-nav-item @click="toggle" class="mainItem" to='/develop'>DEVELOPMENT</b-nav-item> -->
          </b-col>
          <b-col class="FeaturedNews-mob">
            <h3 class="menuFeat">
              Featured News
            </h3>
            <MenuFeat />
          </b-col>
        </b-row>
        <b-row class="BannerHideMob">
          <b-col>
            <b-nav-item v-b-modal.app>
              <b-img
                class="menuBanner"
                :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2021/03/follow-1.jpeg'"
              ></b-img>
            </b-nav-item>
            <b-modal centered='true' hide-footer='true' hide-header='true' id="app">
              <b-row>
                <b-col>
                  <a
                    :href="config.VUE_APP_GOOGLE_PLAY" target="_blank">
                      <b-img class='appLogo' src='https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-4.png'></b-img>
                  </a>
                </b-col>
                <b-col>
                  <a
                    :href="config.VUE_APP_APPLE_STORE" target="_blank">
                      <b-img class='appLogo'  src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-5.png"></b-img>
                  </a>
                </b-col>
              </b-row>
            </b-modal>
          </b-col>
        </b-row>
        <button class="close-btn" @click="toggle">×</button>
      </div>
    </transition>
  </div>
</template>

<script>
import MenuFeat from "@/components/menuFeat.vue";
import axios from "axios";
export default {
  name: "Menu",
  props: ["config"],
  components: {
    MenuFeat
  },
  data() {
    return {
      menueLink: "menuLink",
      seasons: [],
      code: [],
      reports: [],
      open: false,
      green: [],
      breakC: [],
      CurrentTitle: "",
      CurrentReport: "",
      roundPlayed: [],
      days_away: '',
      live_scoring:''
    };
  },
  computed: {
    filterReports: function() {
      if (Array.isArray(this.reports)) {
        return this.reports.filter(
          reports_entry =>
            reports_entry.report_title.includes("Tournament Entries") ||
            reports_entry.report_title.includes("Score") ||
            // reports_entry.report_title.includes("Live") ||
            reports_entry.report_title.includes("Final")
        );
      } else return this.reports;
    }
  },
  methods: {
    isMobile() {
      if (screen.width <= 428) {
        return true;
      } else {
        return false;
      }
    },
    toggle() {
      this.open = !this.open;
    },
    toggleClass: function() {
      this.isActive = !this.isActive;
    },
    currentReport: function() {
      if (Array.isArray(this.filterReports)) {
        return this.filterReports[this.filterReports.length - 1].report_url;
      } else return this.filterReports.report_url;
    },
    currentTitle: function() {
      if (Array.isArray(this.filterReports)) {
        return this.filterReports[this.filterReports.length - 1].report_title;
      } else return this.filterReports.report_title;
    }
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE +
          "tmticx?randomadd=" +
          new Date().getTime()
      )
      .then(response => {
        this.live_scoring = response.data.live_scoring;
        this.roundPlayed = response.data.rounds_played;
        console.log("this.roundPlayed");
        console.log(this.roundPlayed);
        this.seasons = response.data.tm_params.season_code;
        this.code = response.data.code;
        this.reports = response.data.reports.reports_entry;
        this.days_away = response.data.days_away;
        this.$emit("season", { season: this.seasons });
        this.$emit("course", { course: this.code });
        this.$emit("report", { report: this.currentReport() });
        this.$emit("title", { title: this.currentTitle() });
        this.CurrentTitle = this.currentTitle();
        this.CurrentReport = this.currentReport();
        // return axios.get(
        //   process.env.VUE_APP_WPAPI_URL+"wp/v2/pages/163458"
        // );
      });
    // .then((response) => {
    //   this.breakC = response.data;
    //   return axios.get(
    //     process.env.VUE_APP_WPAPI_URL+"wp/v2/pages/165434"
    //   );
    // })
    // .then((response) => {
    //   this.green = response.data;
    // });
  }
};
</script>

<style scoped>
::v-deep .btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #95c93d;
  border-color: #95c93d;
  box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5);
}
::v-deep .navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
::v-deep .bg-info {
  background-color: #95c93d !important;
}
::v-deep ul.navbar-nav.middleSec {
  margin-left: 15em;
}
::v-deep .row.BannerHideMob {
  margin-top: 60px;
}
::v-deep .RTCDS {
  margin-left: 50px;
}
li.nav-item.menuBar.mobHide.rightLink.LETAS.leadRight {
  margin-right: 60px;
}
.menuContainer {
  padding-left: 0px;
}
::v-deep .navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  /* margin-right: 5.2rem; */
  margin-right: 0rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
img.newTwitter {
  width: 11px;
  margin-top: -4px;
}
.pulse {
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
.LeaderboardBtn {
  background-color: #ff5c39;
  text-transform: uppercase;
  padding: 0px 10px;
  font-weight: 700;
  font-size: 0.675rem;
  color: #fff;
  border-radius: 3px;
  margin-left: 112px;
  margin-right: -190px;
}
.LeaderboardBtnMob {
  background-color: #ff5c39;
  text-transform: uppercase;
  padding: 0px 10px;
  font-weight: 700;
  font-size: 0.675rem;
  color: #fff;
  border-radius: 3px;
}
li.nav-item.rankLinks:hover {
  color: #fff;
  background: #7ba639;
}
li.nav-item.rankLinks > a:hover {
  color: #fff;
}
li.liRanksLinks {
  list-style-type: disc;
  color: #95c93d;
}
div#collapse-2 {
  padding-bottom: 0px;
}
li.rankLinks > a {
  text-decoration: none;
  font-size: 13pt;
  color: #95c93d;
  display: block;
  transition: 0.3s;
  text-align: left;
  padding: 3px;
  width: 100%;
  padding-left: 5px;
}
svg.arrowDown.svg-inline--fa.fa-arrow-down.fa-w-14 {
  font-size: 13px;
  float: right;
  margin-top: 5px;
  margin-right: 5px;
}
li.nav-item.rankingsMenu.mainItem {
  margin-top: 9.5px;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  float: right;
  margin-top: 10px;
}
.statsUpdate > a {
  margin-bottom: 0px !important;
}
li.nav-item.menuBar.mobHide.rightLink {
  margin-right: 80px;
}
@media (min-width: 992px) {
  ::v-deep .navbar-expand-lg {
    /* flex-flow: row nowrap; */
    justify-content: flex-start;
  }
}
li.nav-item.menuBar.mobHide.socialIcon > a {
  padding: 0;
}
li.nav-item.menuBar.mobHide.socialIcon {
  margin-right: 10px;
}
::v-deep .slidein::before {
    box-shadow: 3pt 3pt 0 0 dodgerblue;
    mix-blend-mode: multiply;
    content: "";
}
::v-deep .slidein {
  width: 600px;
  padding: 2em 3em;
  position: absolute;
  z-index: 100;
  left: 0;
  top: -102%;
  background: #fff;
  height: 100% !important;
  /* box-shadow: 5px 5px 10px #035599; */
  transition: all 0.5s ease-in-out;
  z-index: 9909;
  /* overflow: hidden; */
}
::v-deep li {
  list-style-type: none;
}
.menu {
  background-color: #95c93d;
  z-index: 200;
}
.mainItem > .nav-link {
  text-decoration: none;
  font-size: 13pt;
  color: #95c93d;
  display: block;
  transition: 0.3s;
  text-align: left;
  padding: 3px;
  width: 100%;
  padding-left: 5px;
  margin-bottom: 9.5px;
}
.mainItem > .nav-link:hover {
  color: #fff;
  background: #7ba639;
}
.dropdown:hover {
  background: #c8e49a;
}
::v-deep .dropdown > a:hover {
  color: #fff !important;
}
.dropdown {
  text-decoration: none;
  font-size: 13pt;
  color: #95c93d !important;
  display: block;
  transition: 0.3s;
  text-align: left;

  width: 100%;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #95c93d;
  border-color: #95c93d;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5);
}

::v-deep .dropdown > a {
  padding: 0.5rem 0.3rem;
  color: #015798;
}
.navbar {
  padding: 0;
}
.menuFeat {
  padding-left: 4px;
  text-align: left;
  color: #95c93d;
  margin-top: 5px;
  font-weight: 400;
  margin-bottom: 15px;
}
.menuBanner {
  width: 100%;
}
.logoImg {
  /* width: 60px; */
  width: 125px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.burger {
  width: 100%;
}
.burgerOuter {
  background-color: transparent;
  border: none;
  width: 55px;
}
.burgerOuter:hover {
  background-color: transparent;
  border: none;
}

.slidein {
  width: 600px;
  padding: 2em 3em;
  position: absolute;
  z-index: 100;
  left: 0;
  /* top: -102%; */
  top: -120%;
  background: #fff;
  /* height: 850px!important; */
  box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
  transition: all 0.5s ease-in-out;
  z-index: 999;
  display: none;
}

/* Set positioning back to 0 when toggled opened */
.open {
  top: 86px;
  height: 730px !important;
  display: block;
}

/* add a close button in case toggle button is hidden on smaller screens */
.close-btn {
  border: none;
  font-weight: bold;
  font-size: 50px;
  background: transparent;
  position: absolute;
  right: 0;
  top: -25px;
  padding: 0.5em;
  color: #95c93d;
}
.slide-enter,
.slide-leave-active {
  top: -100%;
}
.menuBar > .nav-link {
  color: #fff;
  /* padding: 0 19px; */
  font-weight: 400;
  font-size: 0.775rem;
  letter-spacing: 0.5px;
}
.icon {
  font-size: 14px;
}
.embed-responsive {
  height: 90px;
  width: 291px;
  margin-right: -100px;
  margin-left: 20px;
}
.appLogo {
  width: 100%;
}
@media only screen and (max-width: 1200px) {
  .break {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  /* .rolex{
    display: none;
  } */
  /* .embed-responsive {
    display: none;
  } */
  .embed-responsive {
    height: 90px;
    margin-right: -100px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin: auto;
    background: #00603a;
    z-index: 99;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0px;
    left: 0;
  }
}
@media only screen and (max-width: 768px) {
  .mobHide {
    display: none;
  }
  .logoImg {
    width: 66px;
  }
  .open {
    top: 48px;
  }
}
@media only screen and (max-width: 600px) {
  ::v-deep li.rankLinks > a {
    text-decoration: none;
    font-size: 13pt;
    color: #fff;
    display: block;
    transition: 0.3s;
    text-align: left;
    padding: 3px;
    width: 100%;
    padding-left: 5px;
  }
  .slidein {
    width: 100%;
    height: 100%;
    background-color: #95c93d;
  }
  .FeaturedNews-mob {
    display: none;
  }
  .BannerHideMob {
    display: none;
  }
  ::v-deep .mainItem > .nav-link {
    text-decoration: none;
    font-size: 15pt;
    color: #ffffff;
    display: block;
    transition: 0.3s;
    text-align: left;
    padding: 3px;
    width: 100%;
    padding-left: 5px;
    margin-bottom: 15.5px;
  }
  ::v-deep .mainItem > .nav-link:active {
    text-decoration: none;
    font-size: 15pt;
    color: #ffffff;
    display: block;
    transition: 0.3s;
    text-align: left;
    padding: 3px;
    width: 100%;
    padding-left: 5px;
    margin-bottom: 15.5px;
    background-color: #7ba639;
  }
  ::v-deep .dropdown > a {
    padding: 0.5rem 0.3rem;
    color: #ffffff;
    font-size: 15pt;
    padding-bottom: 25px;
  }
  ::v-deep .mainItem > .nav-link:hover {
    color: #fff;
    background: #7ba639;
    width: 300px;
  }
  ::v-deep .close-btn {
    border: none;
    font-weight: 300;
    font-size: 3em;
    background: transparent;
    position: absolute;
    right: 0;
    top: -11px;
    padding: 0.5em;
    color: #ffffff;
  }
}
</style>
